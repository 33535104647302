.pagenotfound{min-height: 300px;background-color: $white;position: relative;z-index: 10;}
.inner-main{
    background-color: $white;position: relative;
    z-index: 10;padding-top: 80px;padding-bottom: 80px;
    @media(max-width:991px){padding-top: 80px;padding-bottom:80px;}
    @media(max-width:767px){padding-top: 50px;padding-bottom:50px;}

    h2,h3,h4,h5,h6,p,ul,table{
      @include animation-anime(texty, 1.2s, linear);
      @include trans; 
    }
    h3{color: $black;margin-bottom: 25px;}
    h4{color: $black;margin-bottom: 20px;}
    h5{color: $black;margin-bottom: 20px;}
    h6{color: $black;margin-bottom: 20px;}
    p{color: $black;margin-bottom: 20px;
      a{color: $color;}
      .red-color{
        color: red;
      }
    }
    .scrolling-list{
      display: inline-block;width: 100%;margin-bottom:10px;padding-left:10px;color: $black;
      li{display: inline-block;width: 100%;margin-bottom:10px;padding-left: 20px;position: relative;color: $black;font-size:20px;
        a{position: relative;color: $black;font-weight: 600;font-size:20px;
          &:hover{color: $color;}
        }
        b{display: inline-block;color: $black;}
        &:before{position: absolute;content: "";left: 0;top: 10px;width: 8px;height: 8px;background-color: $black;@include bd-radius(50px);}
      }
    }
    .dot-list{
      display: inline-block;width: 100%;margin-bottom:10px;padding-left:10px;color: $black;
      li{display: inline-block;width: 100%;margin-bottom:10px;padding-left: 15px;position: relative;color: $black;
        b{display: inline-block;color: $black;}
        &:before{position: absolute;content: "";left: 0;top: 10px;width: 5px;height: 5px;background-color: $black;@include bd-radius(50px);}
      }
    }
  }
  .spacer-div{padding-left: 25px;}
  .click-text{cursor: pointer;}
  /* Inner Content Panel */

  .faq-page{
    p{font-size: 16px;margin-bottom: 10px;
      .red{color: red;}
    }
    h3{color: $color;}
    .space-left{padding-left: 30px;
      @media(max-width:991px){padding-left: 15px;}
    }
  }
  