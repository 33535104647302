.mobile-post{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
    @media(max-width:1200px){padding-top: 80px;padding-bottom:80px;}
    .bg-layer{z-index: -1;}
    &.tile-bg{
        .bg-layer{
            background-repeat: no-repeat;
            background-size: auto 100%, auto 100%;
            background-position: left top, right center;
            @media(max-width:767px){
                background-size: 30% auto, 60% auto;
                background-position: left top, right top;
            }
        }
        &.lf-anime{
            .bg-layer{
            
                @media(max-width:767px){
                    background-size: 60% auto, 30% auto;
                    background-position: left top, right top;
                }
            }
        }
    }
    &.grad-bg{
        background: $gradientlight;
        .bg-layer{
            background-repeat: no-repeat;
            background-size: auto 110%;
            background-position: left center;
        }
    }
    &.anime{
        h2,h5,p,.simple-btn{
            @include trans;@include animation-anime(text-y, 1.2s, linear);
        }
        img{
            @include trans;@include animation-anime(textx, 1.2s, linear);
        }
        &.lf-anime{
            img{
                @include trans;@include animation-anime(text-x, 1.2s, linear);
            }
        }
    }
}