.header{
    width: 100%;position: relative;height:870px;min-height: 100vh;
    position: relative;z-index: 10;margin-bottom: 50px;overflow: hidden;
    @media(max-width:1800px){height:780px;}
    @media(max-width:1600px){height:800px;}
    @media(max-width:1400px){height:780px;}
    @media(max-width:1200px){height: 750px;}
    @media(max-width:991px){height: auto;}
    @media(max-width:767px){text-align: center;}
    @media(max-width:575px){}
    .header-inner-pnl{
        display: flex;justify-content: space-between;align-items: flex-start;
        padding: 150px 80px 320px;
        @media(max-width:1200px){padding: 150px 65px 320px;}
        @media(max-width:991px){flex-direction: column;align-items: center;padding: 150px 15px 320px;
            .app-store-list{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                li{float: none;margin-right: 0;}
            }
        }
        @media(max-width:767px){padding: 150px 15px 20px;}
        @media(max-width:575px){padding-left: 15px;padding-right:15px;
            .app-store-list{
                flex-direction: column;
                gap: 0;
            }
        }
        .text-panel{max-width: 800px;
            @include trans;@include animation-anime(opacityanime, 1.2s, linear);
            @media(max-width:1600px){max-width: 600px;}
            @media(max-width:1400px){max-width: 500px;}
            @media(max-width:991px){max-width: 100%;text-align:center;}
        }
        .img-panel{
            width: 850px;
            max-width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media(max-width:991px){max-width: 600px;}
            @media(max-width:767px){max-width: 80%;}
            img{
                &:first-child{max-width: 30%;margin-top: 40px;
                    @include trans;@include animation-anime(imganime, 1.2s, linear);
                }
                &:nth-of-type(2){max-width: 33%;margin-top: 60px;
                    @include trans;@include animation-anime(imganime, 1.6s, linear);
                }
                &:nth-of-type(3){max-width: 30%;margin-top: 20px;
                    @include trans;@include animation-anime(imganime, 1.2s, linear);
                }
            }
        }
    }
    .bg-layer{
        top: 90px;
        height: auto;bottom: 0;z-index: -1;
        width: auto;left: 60px;right: 60px;
        background-size: 100% 100%;border-radius: 30px;
        @media(max-width:1200px){left: 45px;right:45px;}
        @media(max-width:991px){left: 15px;right:15px;border-radius: 50px;}
    }
    // Floating Text
    .floating-text{
        position: absolute;left: 390px;bottom: 0px;
        @include animation-anime(floatingtext, 1.2s, linear);@include trans;
        @media(max-width:1200px){font-size: 12px;left:370px;}
        @media(max-width:991px){left: unset;right:40px;bottom:55px;}
        @media(max-width:767px){position: static;}

    }
    // Floating Text

    // Small Mobile Panel
    .small-mobile-pnl{
        position: absolute;left: 55px;bottom: -5px;
        width: 310px;padding: 30px;height: 310px;
        border: 15px solid $white;border-radius: 0px 40px 0px 40px;
        background-image: url(../Images/Bg/hero-bg-sm.png);
        background-size: 100% 100%;z-index: 2;
        background-color: $white;
        @media(max-width:1200px){left: 40px;}
        @media(max-width:991px){left: 10px;}
        @media(max-width:767px){position: static;border-radius:40px;margin:20px auto;}
        @media(max-width:575px){height: 280px;width: 280px;left: 15px;}
        .small-mobile-pnl-inner{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            &::before{
                position: absolute;
                content: "";
                left: -40px;
                top: -74px;width: 32px;
                height: 32px;z-index: 2;
                background-image: url(../Images/Mobiles/cut-border.png);
                background-size: 100% 100%;z-index: 2;
                transform: rotate(90deg);
                @media(max-width:767px){display: none;}
            }
            &::after{
                position: absolute;
                content: "";
                right: -77px;
                bottom: -11px;width: 32px;
                height: 32px;z-index: 2;
                background-image: url(../Images/Mobiles/cut-border.png);
                background-size: 100% 100%;z-index: 2;
                transform: rotate(90deg);
                
                @media(max-width:767px){display: none;}
            }
            img{max-height: 250px;
                @media(max-width:575px){
                    max-height: 220px;
                }
            }
            .crypto-btn{
                position: absolute;width: 65px;height: 60px;
                border-radius: 15px;font-size: 12px;font-weight: 400;
                @include animation-anime(cryptoanime, 1.2s, linear);@include trans;
                img{max-width: 25px;max-height: 25px;}
                &:nth-of-type(1){left: -15px;top: 15px;}
                &:nth-of-type(2){left: -15px;bottom: 30px;}
                &:nth-of-type(3){top: 15px;right: -15px;}
                &:nth-of-type(4){right: -15px;bottom: 30px;}
            }
        }
    }
    // Small Mobile Panel

    // Social Media List
    .header-social-cntnr{
        position: absolute;z-index: 2;
        right:60px;bottom: 0;
        background-color: $white;
        border-radius: 30px 0 0 0;
        @media(max-width:1200px){right: 45px;}
        @media(max-width:991px){right: 15px;}
        @media(max-width:767px){position: static;background-color: transparent;margin-bottom:20px;}
        @media(max-width:575px){right: 15px;}
        ul{
            display: flex;justify-content: center;
            align-items: center;gap: 50px;margin:0;
            padding: 13px 30px;position: relative;
            li{@include animation-anime(socialanime, 1.2s, linear);@include trans;}
            &::before{
                position: absolute;
                content: "";
                right: -1px;
                top: -30px;width: 30px;
                height: 30px;
                background-image: url(../Images/Mobiles/cut-border.png);
                background-size: 100% 100%;z-index: 2;
                
                @media(max-width:767px){display: none;}
            }
            &::after{
                position: absolute;
                content: "";
                left: -29px;
                bottom: 0px;width: 30px;
                height: 30px;
                background-image: url(../Images/Mobiles/cut-border.png);
                background-size: 100% 100%;z-index: 2;
                
                @media(max-width:767px){display: none;}
            }
            img{max-width: 28px;}
            @media(max-width:575px){gap: 20px;}     
            &.social-media-list{
                gap: 10px;
            }
        }
    }
        // Social Media List
}


// Inner Header
.inner-header{
    position: relative;margin-top: 75px;padding-top: 80px;padding-bottom: 100px;
    z-index: 2;overflow: hidden;text-align: center;
    background: $gradientdark;
    .bg-layer{z-index: -1;left: -15px;right: -15px;width: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
    }
    h1,p,ul{@include animation-anime(text-y, 1.2s, linear);@include trans; color: $white;}
    h1{
        font-size: 60px;
        @media(max-width:1200px){font-size: 50px;}
        @media(max-width:991px){font-size: 40px;}
        @media(max-width:767px){font-size: 35px;}
        @media(max-width:575px){font-size: 30px;}

    }
    @media(max-width:991px){margin-top: 70px;padding-top:70px;padding-bottom:80px;
        .app-store-list li{margin-right: 10px;margin-bottom: 10px;}
    }
    @media(max-width:767px){padding-top:50px;padding-bottom:60px;}
    
}
// Inner Header