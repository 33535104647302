/*color*/
$white: #FFFFFF;
$black: #141313;
$color:#1B45CE;
$darkcolor:#1C4C99;
$lightcolor:#D9EEFF;
$lightblue:#2E7EFF;
$grey:#808080;
$sky:#00EEFF;


/*color*/

$gradientdark: linear-gradient(180deg, #1B45CE 0%, #0E2368 100%);

$gradientlight:linear-gradient(to right,  rgba(217,238,255,1) 0%,rgba(142,197,255,1) 100%);