// Apple Store Button
.app-store-list{
  width: 100%;margin:0;display:inline-block;
  &.center{
    display: flex;justify-content: center;align-items: flex-start;
    li{margin-left: 7.5px;margin-right: 7.5px;}
  }
  li{display: inline-block;float: left;margin-right: 20px;margin-bottom: 10px;
    @media(max-width:991px){margin: 0;}
  }
  &.dark{
    li a{
      background-color: $black;color: $white;
      i,span,p,h6{color: $white;}
    
      &:hover{
        background-color: $white;color: $black;border-color: $black;
        i,span,p,h6{color: $black;}
      }
    }
  }
}

.apple-store-btn{
  display: inline-flex;align-items: center;background-color: $white;text-align: left;
  border:1.5px solid $color;padding:3px 10px 3px;min-width: 175px;border-radius: 10px;
  img{margin-right: 10px;max-height: 35px;max-width: 30px;}
  span{color: $black;display: inline-block;margin: 0;text-transform: uppercase;font-weight: 500;line-height: 12px;
    position: relative;top: -5px;font-size: 10px;
  }
  h6{margin: 0;font-weight:600;text-transform: capitalize;line-height: 16px;color: $black;
    position: relative;top: -5px;font-size: 16px;
  }
  i{font-size: 40px;width: 34.28px;margin-right: 10px;color:$black;}
  &.fill,
  &:hover{
    background-color: $color;border-color:$white;
    i,span,h6{color: $white;}
  }
  &.white{
    background-color: $white;border-color:$black;
    i,span,h6{color: $black;}
    &:hover{
      background-color: $black;
      i,span,h6{color: $white;}
    }  
  }
  &.black{
    background-color: $black;border-color:$white;
    i,span,h6{color: $white;}  
  }
  &.trans{
    background-color: transparent;border-color:$white;
    i,span,h6{color: $white;}    
    &:hover{background-color: $black;}
  }
}
// Apple Store Button


// Button List
.btn-list{
  width: 100%;margin:0;display:flex;gap: 10px;
  &.center{justify-content: center;align-items: flex-start;gap: 10px;}
  li{margin-bottom: 10px;
    a,button{
      font-size: 16px;font-weight: 400;border-radius: 35px;color: $color;border: 2px solid $color;padding: 0 5px;
      background-color: rgba(255,255,255,0.1);line-height: 45px;min-width: 150px;text-align: center;backdrop-filter: blur(8px);
      @media(max-width:767px){min-width: 110px;line-height: 40px;font-weight:500;font-size:14px;}

      &:hover{background-color: $color;color: $white;border-color: rgba(0,0,0,0.1);}
    }
    &:first-child{
      a,button{color: $white;background-color: $color;border-color: rgba(0,0,0,0.1);
        &:hover{background-color: rgba(255,255,255,0.1);color: $color;border-color: $color;}
      }
    }
    a.white,button.white{
      backdrop-filter: blur(8px);color: $white;border: 2px solid $white;
      background-color: rgba(255,255,255,0.1);
        &:hover{background-color: $white;color: $color;border-color: $color;}
    }
  }
}
// Button List

// Reg Button
.reg-btn{
  font-size: 18px;font-weight: 400;border-radius: 35px;
  color: $color;border: 2px solid $color;background-color: rgba(255,255,255,0.1);line-height: 40px;
  min-width: 170px;text-align: center;backdrop-filter: blur(8px);
  &.fill{
    background-color: $color;
    color: $white;border-color: rgba(0,0,0,0.1);
    &:hover{
      background-color: rgba(255,255,255,0.1);backdrop-filter: blur(8px);
      color: $color;border-color: $color;
    }
  }
  &:hover{background-color: $color;color: $white;border-color: rgba(0,0,0,0.1);}
}
  // Reg Button


// Simple Button
.simple-btn{
  color: $black !important;font-size: 18px;font-weight: 400;background: transparent !important;
  border: none;width: 122px;text-align: left;display: inline-block;padding: 0;
  i{color: $black;}
  &:hover{color: $color !important;
      i{color: $color;margin-left: 5px;
    }
  }
}
// Simple Button

// Crypto Button
.crypto-btn{
  background: #FFFFFF;display: inline-flex;justify-content: center;align-items: center;flex-direction: column;width: 80px;border-radius: 20px;
  height: 75px;font-size: 14px;color: $lightblue;font-weight: 600;position: relative;z-index: 1;
  img{max-width: 40%;margin-bottom:2px;max-height: 40%;}
  &:before{
    position: absolute;content: "";z-index: -1;
    left: 0;right: 0;bottom: 0;top: 0;border-radius: 20px;
    backdrop-filter: blur(19.379940032958984px);
    box-shadow: 4.43px 4.43px 13.3px 4.43px rgba(0,0,0,0.2);
  }
  &:hover{color: $lightblue;}
}
// Crypto Button