@keyframes Navanime {
  0%   {transform: translateY(-100px);opacity: 0;}
  90%   {transform: translateY(-100px);opacity: 0;}
  100% {transform: translateY(0px);opacity: 1;}
}
@keyframes txtupside {
    0%   {transform: translateY(-100px);opacity: 0;}
    80%   {transform: translateY(-100px);opacity: 0;}
    100% {transform: translateY(0px);opacity: 1;}
}
@keyframes txtdonwside {
    0%   {transform: translateY(100px) scale(0.9);opacity: 0;}
    80%   {transform: translateY(100px) scale(0.9);opacity: 0;}
    100% {transform: translateY(0px) scale(1);opacity: 1;}
}

@keyframes livedown {
    0%   {transform: translateY(100px) ;opacity: 0;}
    80%   {transform: translateY(100px) ;opacity: 0;}
    100% {transform: translateY(0px);opacity: 1;}
}
@keyframes opacityanime {
  0%   {transform: translateY(50px);opacity: 0;}
  100% {transform: translateY(0px);opacity: 1;}
}
@keyframes imganime {
    0%   {transform: translateY(100vh);opacity: 0;}
    100% {transform: translateY(0px);opacity: 1;}
}
@keyframes floatingtext {
    0%   {transform: translateY(-50px);opacity: 0;}
    100% {transform: translateY(0px);opacity: 1;}
}
@keyframes cryptoanime {
    0%   {transform: translateY(100px);opacity: 0;}
    100% {transform: translateY(0px);opacity: 1;}
}
@keyframes socialanime {
  0%   {transform: translateX(200px);opacity: 0;}
  100% {transform: translateX(0px);opacity: 1;}
}
@keyframes translist {
    0%   {transform: translateX(100%);}
    70%   {transform: translateX(100%);}
    100% {transform: translateX(0px);}
}

@-webkit-keyframes opac{
    0% { opacity: 0; }
    80% { opacity: 0; }
    100% {  opacity: 1;}
}
@keyframes opac{    
    0%{opacity: 0;}
    80% { opacity: 0; }
    100%{opacity: 1;}
}

@-webkit-keyframes texty{
    0% { -webkit-transform: translateY(100px);opacity: 0; }
    100% { -webkit-transform: translateY(0px);  opacity: 1;}
}
@keyframes texty{    
    0%{transform: translateY(100px);opacity: 0;}
    100%{transform: translateY(0px);opacity: 1;}
}
@-webkit-keyframes text-y{
    0% { -webkit-transform: translateY(-100px);opacity: 0; }
    100% { -webkit-transform: translateY(0px);  opacity: 1;}
}
@keyframes text-y{    
    0%{transform: translateY(-100px);opacity: 0;}
    100%{transform: translateY(0px);opacity: 1;}
}

@-webkit-keyframes textx{
    0% { -webkit-transform: translateX(100px);opacity: 0; }
    100% { -webkit-transform: translateX(0px);  opacity: 1;}
}
@keyframes textx{    
    0%{transform: translateX(100px);opacity: 0;}
    100%{transform: translateX(0px);opacity: 1;}
}
@-webkit-keyframes text-x{
    0% { -webkit-transform: translateX(-100px);opacity: 0; }
    100% { -webkit-transform: translateX(0px);  opacity: 1;}
}
@keyframes text-x{    
    0%{transform: translateX(-100px);opacity: 0;}
    100%{transform: translateX(0px);opacity: 1;}
}
@keyframes text-xy{    
    0%{transform: translateZ(100px);opacity: 0;}
    80%{transform: translateZ(100px);opacity: 0;}
    100%{transform: translateZ(0px);opacity: 1;}
}
