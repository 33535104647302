/* Custome Css */
*{
    margin: 0;padding: 0;image-rendering: -o-crisp-edges;  @include trans();
    image-rendering: -webkit-optimize-contrast;-ms-interpolation-mode: nearest-neighbor;
  }
  body,html,main{-webkit-font-smoothing: antialiased}
  a,a:focus,button,button:focus{outline: 0 !important;box-shadow: none !important;}
  a{display: inline-block;text-decoration: none !important;}
  .bg-layer{opacity: 1;background-size: auto;background-position: center center;background-repeat: no-repeat;position: absolute;left: 0;top: 0;}
  .bg-layer,iframe,video,source{height: 100%;width: 100%}
  img,picture,svg{max-width: 100%;}
  .full-div{display: inline-block;width: 100%;}
  .half-div{display: inline-block;width: 50%;float: left;}
  .txt-right,.text-right{text-align: right;}
  .txt-center,.txt-centr,.text-center{text-align: center;}
  .txt-left,.text-left{text-align: left;}
  .flex-div{display: flex;justify-content: space-between;width:100%;}
  .flex-div-sm{display: flex;justify-content: space-between;width:100%;
    @media(max-width:767px){display: inline-block;text-align:center;}
  }
  .flex-div-xs{display: flex;justify-content: space-between;width:100%;
    @media(max-width:575px){display: inline-block;text-align:center;}
  }
  .mobile-view-display{
    @media(min-width:768px){display: none;}
  }
  @media(max-width:575px){
    .container{padding-left: 0;padding-right: 0;}
  }
  img{max-width: 100%;}
  ul{padding-left: 0;}
  .pd-r-0{padding-right: 0;}
  .pd-l-0{padding-left: 0;}
  

  
  
  /**** divider ***/
  .spacer-10{width: 100%;height: 10px;display: block;clear: both;}
  .spacer-20{width: 100%;height: 20px;display: block;clear: both;}
  .spacer-30{width: 100%;height: 30px;display: block;clear: both;}
  .spacer-40{width: 100%;height: 40px;display: block;clear: both;}
  .spacer-50{width: 100%;height: 50px;display: block;clear: both;}
  .spacer-60{width: 100%;height: 60px;display: block;clear: both;}
  
  hr{display: block;clear: both;border-top: solid 1px #ddd;margin: 40px 0 40px 0;}
  input[type="checkbox"]{accent-color: $black;}
  ::placeholder{color: $white;}
  :-ms-input-placeholder {color: $white;}
  ::-ms-input-placeholder {color: $white;}
  body{
    min-height: 100%;height: auto;
    font-family: "Sora", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;color: $black;font-size: 18px;font-style: normal;
  }
  body{overflow-x: hidden !important;}
  ul{list-style: none;}
  /* Custome Css */
  
  // Page Not Found
  .pagenotfound{
    width: 100%;height: calc(100vh - 375px);
    display: flex;justify-content: center;align-items: center;min-height:300px;
    h1{font-weight: 600;margin-top: 50px;}
  }
  // Page Not Found
  
  .dot-list{
    display: flex;
    flex-direction: column;
    li{
      margin-bottom: 0px;
      padding-left: 15px;
      position: relative;
      &:before{
        position: absolute;
        content: "";
        left: 0;top: 8px;
        border-radius: 50%;
        background-color: $grey;
        width: 5px;height: 5px;
      }
    }
  }
  
  // Pagination
  .pagination{justify-content: flex-end;
    .page-item{margin: 0 10px;
      @media(max-width:575px){margin: 0 5px;}
      &.active{
        .page-link{opacity: 1;color: $color;
          &:before,
          &:after{opacity: 1;}
        }
      }
      // &:first-child,
      // &:last-child{
      //   .page-link{  
      //     border-radius: 50%;width: 35px;height:35px;color: $grey;font-size: 35px;padding: 0;display: inline-flex;
      //     justify-content: center;align-items: center;line-height: 0;
      //     span{position: relative;top: -4px;}
      //     &:before,
      //     &:after{display: none;}
      //   }
      // }
      .page-link{
        border-radius: 0;border: 0;color: $black;opacity: 0.72;font-size: 19px;padding: 5px 5px 0px 5px;
        font-weight: 600;position: relative;background-color: transparent;
        .visually-hidden{display: none;}
        &:hover{opacity: 1;color: $color;}
        &:before{position: absolute;content: '';left: 0;right: 0;bottom: 0;height: 1px;background-color: $color;opacity: 0;}
        &:after{position: absolute;content: '';left: 15%;right: 15%;bottom: -4px;height: 1px;background-color: $color;opacity: 0;}
      }
    }
  }
  // Pagination
  
  // Dropdown 
  .dropdown-trans{
    &.show{
      button{color: $black;}
    }
    button{
      background-color: transparent !important;box-shadow: none !important;color: $black;
      font-weight: 700;font-size: 20px;border: none !important;padding: 0;
      i{font-size: 26px;}
      &:after{display: none;}
      &.show,
      &:hover{color: $black !important;}
    }
    .dropdown-menu{
      padding: 0;border-radius:10px;
      a{font-size: 16px;font-weight: 600;color: $black;
        padding: 8px 15px;
        &:first-child{border-radius:10px 10px 0 0;}
        &:last-child{border-radius:0 0 10px 10px;}
        &:hover{background-color: $black;color: $white;}
      }
    }
  }
  // Dropdown 

  // Table
  .table-container{width: 100%;overflow:auto;}
  .table{
    min-width: 960px;
    th{color: $black;font-size: 14px;text-align: right;
      &:nth-of-type(1),
      &:nth-of-type(2){text-align: left;}
    }
    td{color: $grey;font-size: 14px;text-align: right;
      border-color: #D3D3D3;color:#474747;
      &:nth-of-type(1),
      &:nth-of-type(2){text-align: left;}
      &.red-color{color: #EA3943;}
      &.green-color{color: #16C784;}
    span{
      font-size: 12px;display: inline-block;width: 100%;
    }
    }
  }
  // Table
  
  // Nav Tabs
  .nav-tabs{
    border: none;gap: 10px;
    // @media(max-width:991px){justify-content: center;}
    .nav-item{border: none;
      .nav-link{
        border: none;font-size: 16px;color: $grey;padding: 8px 10px;
        font-weight: 400;@include trans;position: relative;border-radius: 10px;
        background-color: transparent;display: flex;gap: 5px;
        @media(max-width:767px){font-size: 14px;padding: 8px 8px;}
        @media(max-width:575px){font-size: 12px;padding: 8px 5px;}
        .img-pnl{
          width: 20px;height: 20px;display: inline-flex;justify-content: center;align-items: center;position: relative;
          img{
            position: absolute;max-width: 20px;max-height: 20px;
            left: 0;top:0;@include trans;
            &:nth-of-type(1){opacity: 1;}
            &:nth-of-type(2){opacity: 0;}
          }
        }
        @media(max-width:575px){font-size: 16px;}
        &:hover,
        &.active{
          color: $color;border-radius: 10px;
          background-color: rgba(27,69,206,0.1);
          .img-pnl{
            img{
              &:nth-of-type(1){opacity: 0;}
              &:nth-of-type(2){opacity: 1;}
            }
          }
          &:before{
            width: 45px;    
          }
        }
      }
    }
  }
  

@media(min-width:991px){  
  html,body{
    width: 100%;
    height: 100vh;
  }
}
.loader-main{
    width: 100%;height: 100vh;background-color: $white;display: flex;justify-content: center;
    align-items: center;position: absolute;left: 0;top: 0;z-index: -2;
    animation: opacnone 1.5s linear;
    img{max-width: 450px;width: 80%;animation: logoscale 1s linear;transform: scale(1.2);
      @media(max-width:1400px){ max-width: 350px;}
      @media(max-width:1200px){  max-width: 320px;}
      @media(max-height:900px){  max-width: 350px;}
      @media(max-height:750px){  max-width: 320px;}
    }
}
@keyframes opacnone{    
  0% { opacity: 1;z-index: 999;}
  80% { opacity: 1;z-index: 999;}
  100% {  opacity: 0;z-index: -2;}
}
@-webkit-keyframes logoscale{
  0% { opacity: 0; transform: scale(1);}
  100% {  opacity: 1;transform: scale(1.2);}
}
@keyframes logoscale{    
  0% { opacity: 0; transform: scale(1);}
  100% {  opacity: 1;transform: scale(1.2);}
}


.shery{
  width: 100%;
  height: 500px;
}

// #coinmarketcap-widget-marquee{
//   @media(max-width:991px){position: fixed;bottom:0;left:0;right:0;z-index: 999;}
// }