.navbar{
  position: absolute;z-index: 99;left: 0;right: 0;top: 0;background-color: transparent;
  background-color: $white;padding: 20px 45px;
  @include animation-anime(Navanime, 3s, linear);
  &.navbar-special{@include animation-anime(text-y, 1s, linear);}
  @media(max-width:1200px){padding: 20px 15px;}
  @media(max-height:750px){padding: 10px 15px;}
  @media(max-width:991px){padding:15px 15px;position: fixed;
    @include animation-anime(Navanime, 1.8s, linear);
    border-bottom: 1px solid $color;
  }
  @media(max-width:767px){padding:10px 0px;}
  @media(max-width:575px){
    .navbar-logo {max-width: 120px;}
  }
  .btn-list li{margin: 0;
    @media(min-width:992px){
      &:nth-of-type(2){display: none;}
    }
    @media(max-width:991px){
      &:nth-of-type(1){display: none;}
    }
  }
  .btn-list li a, .btn-list li button{line-height: 45px;
    @media(max-width:1400px){line-height: 40px;}
    @media(max-width:767px){line-height: 35px;}
  }
    .navbar-nav{
      @media(max-width:991px){padding-top: 15px;padding-bottom:5px;}
        .nav-link{
            color: $grey;font-size: 16px;font-weight: 600;border-radius: 30px;padding: 8px 25px; 
            @media(max-width:1600px){font-size: 15px;padding: 8px 15px;}
            @media(max-width:1400px){font-size: 14px;padding: 8px 10px;}
            @media(max-width:1200px){font-size: 12px;padding: 8px 8px;}
            @media(max-width:991px){font-size: 14px;padding: 8px 15px;width: auto;}
            &.blink,
            &:hover{color: $white;background-color: $color;}
        }
    }
    .btns-cntnr{display: flex;align-items: center;gap: 15px;
      .reg-btn{
        @media(max-width:991px){line-height: 40px;min-width: 120px;font-size:14px;}
        @media(max-width:767px){line-height: 35px;}
        @media(max-width:575px){min-width: 100px;font-size:12px;}
      }
    }
    @media(min-width:992px){
      .btns-cntnr{display: none;}
    }
    @media(max-width:991px){
      .btn-list{width: auto;display: none;}
      .navbar-toggler {
        border: none;padding: 0;width: 30px;height: 30px;position: relative;
        display: flex;justify-content: center;align-items: center;
        span {
          display: inline-block;width: 100%;height: 2px;opacity: 0;
          background-image: none;background-color: $color;@include trans();
        }
        &:before {
          position: absolute;content: "";left: 0;right: 0;
          top: 13px;height: 2px;background-color: $color;@include trans();
          transform: rotate(-45deg);
        }
        &:after {
          position: absolute;content: "";left: 0;right: 0;bottom: 15px;height: 2px;
          background-color: $color;@include trans();transform: rotate(45deg);
        }
        &.collapsed {
          span {opacity: 1;}
          &:before {transform: rotate(0deg);top: 5px;}
          &:after{transform: rotate(0deg);bottom: 5px;}
        }
        &:hover {
          &:before,
          &:after,
          span {background-color: $black;}
        }
      }
  
    }
}

@media(min-width:992px){
  .custom-navbar {display: flex;align-items: center;justify-content: space-between;
    .navbar-logo {flex: 0.5; display: flex;align-items: center;}
    .navbar-nav{background: transparent;border-radius: 30px;gap: 5px;padding: 4px;border: 1px solid $grey;}
    .navbar-links {flex: 2 1; display: flex;justify-content: center; }  
    .navbar-links .nav {align-items: flex-end; display: flex;gap: 1rem; }
    .btn-list {flex: 0.5;display: flex;justify-content: flex-end;align-items: right;
        li{margin-bottom: 0;}
        }  
    }
}