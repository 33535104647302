input[type=number]{-moz-appearance: textfield;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;}
form{text-align: center;
    .form-group{
        border-radius: 15px;border-color: transparent;background-color: rgba(255,255,255,0.1);
        color: $grey;font-size: 14px;border: 1.5px solid rgba(0, 0, 0, 0.12);backdrop-filter: blur(11px);
        padding: 5px 15px;margin-bottom: 15px;text-align: left;
        @media(max-height:750px){border-radius: 10px;}
    }
    label{color: #404040;font-weight: 600;font-size: 16px;margin: 0 !important; 
        @media(max-height:750px){font-size: 14px;}
    }
    select,select.form-control,input,input.form-control{
        height: 20px;border-radius: 0;border-color: transparent !important;background-color: transparent !important;
        color: $grey;font-size: 14px;border: transparent !important;padding: 0;
        &::placeholder {color: #979797;}
        &:focus,&:hover{box-shadow: none;border-color: $darkcolor;}
        
    }
    textarea,textarea.form-control{
        border-radius: 0;border-color: transparent !important;background-color: transparent !important;
        color: $grey;font-size: 14px;border: transparent !important;padding: 0;
        min-height: 120px;max-height: 120px;max-width: 100%;min-width:100%;
        @media(max-height:900px){min-height: 80px;max-height:80px;}
        @media(max-height:750px){min-height: 60px;max-height:60px;}

        &:focus,&:hover{box-shadow: none;border-color: $darkcolor;}
    }
    .submit-btn{
        background-color: $color;width: 100%;border-radius: 35px;line-height: 32px;font-weight: 600;color: $white;max-width: 400px;margin:10px auto 0;
        &:hover{background-color: $darkcolor;}
        @media(max-height:750px){margin:0 auto;}
    }
}