h1{font-size:85px;font-weight: 700;line-height: 85px;}
h2{font-size: 80px;font-weight: 700;}
h3{font-size: 40px;font-weight: 700;}
h4{font-size: 35px;font-weight: 700;}
h5{font-size: 24px;font-weight: 500;}
h6{font-size: 20px;font-weight: 400;}

p{font-size: 18px;font-weight: 400;}
.apple-store-btn span{font-size: 10px;}

@media(max-width:1600px){
  h1{font-size:80px;line-height: 80px;}
  h2{font-size: 70px;}
  h3{font-size: 35px;}
  h4{font-size: 30px;}
  h5{font-size: 22px;}
  h6{font-size: 18px;}
  p{font-size: 17px;}
}
@media(max-width:1400px){
  h1{font-size:80px;line-height: 80px;}
  h2{font-size: 60px;}
  h3{font-size: 32px;}
  h4{font-size: 28px;}
  h5{font-size: 20px;}
  h6{font-size: 17px;}
  p{font-size: 16px;}
}
@media(max-width:1200px){
  h1{font-size:70px;line-height: 70px;}
  h2{font-size: 50px;}
  h3{font-size: 30px;}
  h4{font-size: 26px;}
  h5{font-size: 18px;}
  h6{font-size: 17px;}
  p{font-size: 16px;}
}
@media(max-height:900px){
  h1{font-size:60px;line-height: 60px;}
  h2{font-size: 50px;}
  h3{font-size: 26px;}
  h4{font-size: 28px;}
  h5{font-size: 20px;}
  h6{font-size: 16px;}
  p{font-size: 16px;}
}
@media(max-height:750px){
  h1{font-size:50px;line-height: 50px;}
  h2{font-size: 40px;}
  h3{font-size: 24px;}
  h4{font-size: 26px;}
  h5{font-size: 18px;}
  h6{font-size: 16px;}
  p{font-size: 16px;}
}
@media(max-width:991px){
  h1{font-size: 55px;line-height: 55px;}
  h2{font-size: 40px;}
  h3{font-size: 26px;}
  h4{font-size: 24px;}
  h5{font-size: 20px;}
  h6{font-size: 18px;}
  p{font-size: 18px;}
}

@media(max-width:767px){
  h1{font-size: 42px;line-height: 42px;}
  h2{font-size: 32px;}
  h3{font-size: 24px;}
  h4{font-size: 22px;}
  h5{font-size: 18px;}
  h6{font-size: 16px;}
  p{font-size: 16px;}
}
@media(max-width:575px){
  h1{font-size: 32px;line-height: 35px;}
  h2{font-size: 28px;}
  h3{font-size: 24px;}
  h4{font-size: 22px;}
  h5{font-size: 18px;}
  h6{font-size: 16px;}
  p{font-size: 16px;}
}

.inner-main{
  h3{font-size: 30px;font-weight: 600;
    @media(max-width:767px){font-size: 26px;}
  }
  h4{font-size: 24px;font-weight: 600;
    @media(max-width:767px){font-size: 22px;}
  }
  h5{font-size: 20px;font-weight: 600;
    @media(max-width:767px){font-size: 18px;}
  }
  h6{font-size: 18px;font-weight: 600;
    @media(max-width:767px){font-size: 16px;}
  }
  p{font-size: 18px;font-weight: 400;
    b{font-size: 20px;font-weight: 600;}
    @media(max-width:767px){font-size: 16px;
      b{font-size: 18px;}
    }
  }
}