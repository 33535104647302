// Accordian
.accordion{text-align: left;
    padding:0px 60px;
    @media(max-width:1200px){padding: 0 40px;}
    @media(max-width:991px){padding: 0 30px;}
    @media(max-width:767px){padding: 0 15px;}
  .accordion-item{margin-bottom: 0px;border:none;border-radius: 10px;
    margin-bottom: 15px;box-shadow: 2px 3px 20px 0px rgba(0, 0, 0, 0.12);animation: texty 1.2s linear;
    h2{animation: unset;}
    .accordion-header{position: relative;margin:0;
      button{color: $color;font-size: 16px;font-weight: 600;width:100%;text-align: left;border: none;background-color: transparent;
        &:after{display: none;}
        @media(max-width:767px){font-size: 16px;}
        span{width: 32px;height: 32px;display: inline-block;position: absolute;right:5px;top:3px;
          background: none;text-align: center;-webkit-background-clip: unset;-webkit-text-fill-color: unset;background-clip: unset;
          &:before{
            position: absolute;content: "-";width: 32px;height:32px;top: 10px;left: 0;right: 0;@include trans;
            opacity: 1;color: $color;font-size: 22px;
            
          }
          &:after{position: absolute;content: "+";width: 32px;height:32px;top: 10px;right: 0px;bottom: 0;@include trans;
            opacity: 0;color: $color;font-size: 22px;
            
          }
        }
        &.collapsed{
          span{
            &:after{opacity: 1;}
            &:before{opacity: 0;}
          }
        }
        &:hover{color: $lightblue;
          span{
            &:after,
            &:before{
              color: $lightblue;
            }
          }
        }
      }
    }
  }
  .accordion-body{color: $grey;font-size: 16px;
    font-weight: 300;padding-top:15px;padding-bottom:25px;
    p{color: $black;font-size: 16px;font-weight: 300;color: $grey;max-width: 90%;margin: 0;}
  }
}
// Accordian

