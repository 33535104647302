// animation: Navanime 4s linear;
.web-footer{position: absolute;right: 0;bottom:65px;z-index: 99;display: none;justify-content: space-between;align-items: center;padding: 0 50px;left: 0;
  // @media(max-height:750px){padding: 0 30px;}
  @media(max-height:650px){bottom: 60px;}
  @media(max-width:1200px){padding: 0 25px;}
  p,ul{margin: 0;}
  p{color: $black;font-weight: 500;
    text-shadow: 0px 0px 10px rgba(255,255,255,0.5);font-size: 14px;display: flex;gap:5px;
    @media(max-width:1400px){font-size: 13px;}
    @media(max-width:1200px){font-size: 12px;}
    a{color: $black}
    @include animation-anime(txtdonwside, 3.2s, linear);
  }
  .social-media-list{
    @include animation-anime(txtdonwside, 3.2s, linear);
    li a{background-color: $color;border-radius: 50%;}
  }
  @media(min-width:992px){display: flex;
    &.white{
      p{color: $white;
        a{color: $white}
      }
    }
  }
}
.Liveprice-cntnr{width: 100%;overflow: hidden !important;position: fixed;bottom: 0;left: 0;right: 0;z-index: 99999;
  @include animation-anime(livedown, 2.5s, linear);
}
.full-main{
    width: 100%;height: 100vh;background-color: $white;position: relative;
    overflow: hidden !important;
    @media(min-width:992px){
      .footer{display: none;}
    }
    @media(max-width:991px){
      .footer{padding-bottom: 90px;}
    }
    @media(max-width:991px){height: auto;display: flex;flex-direction: column;}
    .full{
      width:100%;height: 100vh;display: flex;background-color: $white;
      @media(min-width:992px){opacity: 1;position: absolute;overflow:hidden !important;}
      @media(max-width:991px){height: auto;min-height:unset;
        &:not(.LandingHome){padding-top: 50px;padding-bottom: 50px;
          @media(max-width:991px){padding-top: 30px;padding-bottom:30px;}
        }
      }
      @media(max-width:767px){
        &:not(.LandingHome){padding-top: 50px;}
      }
      // @media(max-width:991px){min-height: 100vh;height:auto;}
      &.flex-d{
        .full-main-inner{flex-direction: column;}
      }
      .full-main-inner{position: relative;display: flex;justify-content: center;align-items: center;height: 100%;width: 100%;padding: 0 50px;z-index: 2;
        @media(min-width:992px){overflow:hidden !important;}
        @media(max-height:900px){padding: 0 30px;}
        @media(max-width:991px){padding:0 15px;height:auto;}
        .bg-layer{z-index: -1;}
      }
      &.active{opacity: 1;z-index: 90;}
      p{font-size: 20px;max-width: 1100px;margin: 0 auto 15px;font-weight: 500;color: $grey;
        span{color: $color;}
        @media(max-width:1400px){font-size: 18px;}
        @media(max-width:1200px){font-size: 16px;}
        @media(max-height:900px){font-size: 16px;}
        @media(max-height:750px){font-size: 14px;}
        @media(max-width:767px){font-size: 16px;}
      }
    }
}


.full-main{
  // Landing Panel
  .LandingPanel{
    @media(max-width:991px){margin-top: 100px;}
    @media(max-width:767px){margin-top: 80px;}
    .full-main-inner{
      flex-direction: column;text-align: center;
      .landing-text{max-width: 1300px;margin-bottom: 100px;
        @media(max-width:991px){
          @include trans;  animation: text-y  0.8s linear;
          margin-bottom: 0;
        }
        h1{margin-bottom: 30px;
          @media(max-height:900px){margin-bottom: 20px;}
          @media(min-width:992px){
            @include animation-anime(txtupside, 2s, linear);
          }
        } 
        p{
          @media(min-width:992px){font-size: 18px;
            @include animation-anime(txtdonwside, 2s, linear);
          }
          @media(max-height:750px){font-size: 16px;}
          &:last-child{
            @media(max-height:900px){margin-bottom: 120px;}
            @media(max-height:750px){margin-bottom: 150px;}
            @media(max-width:991px){margin-bottom: 20px;}
          }
        }
      }
      .bottom-pnl{position: relative;
        @media(max-width:991px){
          @include trans;  animation: texty  0.8s linear;
        }
        img{max-width: 550px;
          @media(max-width:1200px){max-width: 500px;}
          @media(max-height:650px){max-width: 450px;}
          @media(max-width:991px){max-width: 500px;}
          @media(min-width:992px){
            @include animation-anime(txtdonwside, 3s, linear);
          }
          @media(max-width:575px){max-width:320px;}
        }
        @media(min-width:992px){position: absolute;left: 0;bottom: 55px;right: 0;height: 290px;overflow: hidden;}
        @media(max-height:650px){bottom: 14px;}
        @media(max-width:991px){bottom: unset;}
      }
      &:before{
        position: absolute;content: "";left: 40px;right: 40px;bottom: 0;height: 220px;border-radius: 40px 40px 0 0;
        background: linear-gradient(to bottom,  rgba(27,59,206,1) 0%,rgba(0,15,255,0.2) 100%);

        @media(min-width:992px){
          @include animation-anime(txtdonwside, 2.4s, linear);
        }
        @media(max-width:1200px){left: 20px;right:20px;}
        @media(max-width:991px){left: 0;right:0;border-radius:0;height:350px;
          background: linear-gradient(to bottom,  rgba(27,59,206,1) 0%,rgba(255,255,255,0.2) 100%);
        }
        @media(max-width:575px){height: 200px;}
      }
    }
  }
  // Landing Panel



  .LivePrice{
    @media(max-width:991px){
      .full-main-inner{margin-top: 50px;
        p{max-width: 700px;}
      }
    }
    @media(max-width:767px){
      .full-main-inner{margin-top: 50px;
        p{max-width: 500px;}
      }
    }
    @media(max-width:575px){
      .full-main-inner{margin-top: 50px;
        h2,
        p{max-width: 300px;}
      }
    }
    .full-main-inner{text-align: center;padding: 0;
      &:before{
        position: absolute;content: "";right: 0;top: 0;width: 500px;bottom:0;
        background-image: url(../Images/Bg/blue-bg.png);
        background-size: 100% 100%;z-index: -1;
          @media(max-width:991px){display: none;}
      }
      &:after{
        position: absolute;content: "";left: 0;top: 0;width: 500px;bottom:0;
        background-image: url(../Images/Bg/blue-bg.png);transform: rotate(180deg);
        background-size: 100% 100%;z-index: -1;
        max-width: 100%;
        @media(max-width:991px){display: none;}
      }
      .live-price-text{position: relative;display: inline-block;
        .crypto-span{
          position: absolute;width: 70px;height: 70px;border-radius: 50%;display: flex;justify-content: center;align-items: center;z-index: 2;
          box-shadow: -10px 14px 14px 0px rgba(0, 0, 0, 0.16);
          @media(max-height:900px){width: 50px;height:50px;}
          @media(max-width:1200px){width: 50px;height:50px;}
          @media(max-width:575px){width: 35px;height:35px;}
          img{max-width: 50%;max-height: 50%;}
          &.purple-span{background: linear-gradient(0deg, #4F2B8A 0%, #AB78FF 100%);}
          &.etherium-span{background: linear-gradient(0deg, #3E5EBD 0%, #94AEFF 100%);}
          &.solana-span{background: linear-gradient(0deg, #170E2B 0%, #210066 100%);}
          &.btc-span{background: linear-gradient(135deg, #FDE560 14.64%, #FF6F00 85.36%);
            img{width: 100%;max-width: 100%;height: 100%;max-height: 100%;}
          }
          &:nth-of-type(1){left: -70px;top: -70px;
            @media(max-height:900px){left: -50px;top: -50px;}
            @media(max-height:1200px){left: -50px;top: -50px;}
            @media(max-width:575px){left: 0px;top: -15px;}
          }
          &:nth-of-type(2){right: -70px;bottom: 15px;
            @media(max-height:900px){right: -50px;bottom: 15px;}
            @media(max-width:1200px){right: -50px;bottom: 15px;}
            @media(max-width:575px){right: 0px;bottom: 35px;}
          }
          &:nth-of-type(3){left: 25px;bottom: 20px;
            @media(max-height:900px){left: 0px;bottom: 20px;}
            @media(max-width:1200px){left: 0px;bottom: 20px;}
            @media(max-width:991px){left: 0px;bottom: 0px;}
            @media(max-width:767px){left: 0px;bottom: 35px;}
          }
          &:nth-of-type(4){right: 40%;top: -70px;
            @media(max-height:900px){right: 40%;top: -50px;}
            @media(max-width:1200px){right: 40%;top: -50px;}
          }

        }
      }
      #Liveprice{width: 100%;overflow: hidden !important;}
      .live-bar{width: 100%;max-width: 95%;width:1500;margin: 0 auto;}
      
    }
    @media(min-width:992px){
      .live-price-text{
          span{
            &:nth-of-type(1){transform:translate(-200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(2){transform:translate(200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(3){transform:translate(200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(4){transform:translate(-200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
          }
        }
        h2,p{
          transform:translateY(-200%);opacity: 0;@include trans;
          &.appear{transform: translateY(0);opacity: 1;}
        }
        .live-bar{
          transform:translateX(200%);opacity: 0;
          &.appear{
            transform: translateX(0);
            opacity: 1;
          }
        }
    }
    
    @media(max-width:991px){
      &.anime{
        p,h2,
        .live-price-text{
          @include trans;  animation: text-y  0.8s linear;
        }
      }
    }
  }
  // First Dex Acoount
  .FirstDexAcoount{ 
    .full-main-inner{flex-direction: column;text-align: center;padding: 0;
      p{font-size: 16px;
        @media(min-width:992px){
          &:last-child{margin: 0;}
        }
      }
    }
    .step-list-container{width: 100%;overflow: hidden;}
    .step-list{display: flex;gap: 0px;max-height: 480px;justify-content: center;
      margin:20px auto;max-width: 95%;
      @media(max-height:900px){max-height: 350px;align-content: center;max-width: 900px;}
      @media(max-height:750px){max-height: 280px;}
      @media(max-height:650px){max-height: 250px;}
      @media(max-width:1200px){gap: 15px;}
      li{max-height: 100%;display: flex;align-items: flex-start;
        &:nth-of-type(1){justify-content: flex-end;}
        &:nth-of-type(2){justify-content: center;}
        &:nth-of-type(3){justify-content: flex-start;}
        img{max-height:100%;max-width: 100%;}
      }
    }
    @media(min-width:992px){
      .first-dex-text{opacity: 0;transform: translateY(-150px);
        &.appear{opacity: 1;transform: translateY(0px);}
      }
      .step-list{transform: translateX(110%);
        &.transform{transform: translateX(0%);}
      } 
    }
    @media(max-width:991px){
      .first-dex-text{padding-left:15px;padding-right: 15px; }
      &.anime{
        .first-dex-text{
          @include trans;  animation: texty  0.8s linear;
        }
        .step-list{
          transform: unset;max-width: 95%;
          justify-content: center;margin:0 auto;
          @include trans;  animation: text-y  0.8s linear;
          
        }
      }
    }
  }
  // First Dex Acoount

  // Largest Dex
  .LargestDex{
    .full-main-inner{
      .crypto-text-pnl{
        .img-pnl{
          span{
            img{max-width: 50%;max-height: 50%;}
            &:nth-of-type(1){left: 15px;top: -40px;
              @media(max-width:1200px){left: 0;top:-20px}
            }
            &:nth-of-type(2){right: 15px;top: -40px;
              @media(max-width:1200px){right: 0;top: -20px;}
            }
            &:nth-of-type(3){right: -35px;top: 35%;
              img{width: 100%;max-width: 100%;height: 100%;max-height: 100%;}
              @media(max-width:1200px){right: -20px;top: 45%;}
            }
            &:nth-of-type(4){left: -5px;bottom: 15%;
              @media(max-width:1200px){left: -20px;bottom:10%;}
            }
          }
        }
      }       
    }

    @media(min-width:992px){
      .crypto-text-pnl{
        .img-pnl{opacity: 0;transform: translateX(-100%);
          &.trans-left{opacity: 1;transform: translateX(0%);}
          span{
            &:nth-of-type(1){transform:translate(-200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(2){transform:translate(200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(3){transform:translate(200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(4){transform:translate(-200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
          }
        }
        .txt-pnl{
          opacity: 0;transform: translateX(100%);
          &.trans-right{opacity: 1;transform: translateX(0%);}
        }
         
      }
    }
    
    @media(max-width:991px){
      &.anime{
        .crypto-text-pnl{
          .img-pnl{
            @include trans;  animation: texty  0.8s linear;
          }
          .txt-pnl{
            @include trans;  animation: text-y  0.8s linear;
          }
        }
      }
    }
  }
  // Largest Dex

  // Swapcrypto
  .Swapcrypto{
    .full-main-inner{
      .crypto-text-pnl{
        .img-pnl{
          span{
            &:nth-of-type(1){right: -20px;top: -40px;
              @media(max-width:1200px){right:0;top:-20px}
            }
            &:nth-of-type(2){left: -20px;bottom: 10%;
              @media(max-width:1200px){left: -30px;bottom:5%;}
            }
            &:nth-of-type(3){background: linear-gradient(0deg, #3E5EBD 0%, #94AEFF 100%);left: -35px;top: 15%;
              @media(max-width:1200px){left: -20px;top:5%;}
            }
          }
        }
      }       
    }

    @media(min-width:992px){
      .crypto-text-pnl{
        .img-pnl{opacity: 0;transform: translateX(-100%);
          &.trans-left{opacity: 1;transform: translateX(0%);}
          span{
            &:nth-of-type(1){transform:translate(200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(2){transform:translate(-200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0);}
            }
            &:nth-of-type(3){transform:translate(-200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
          }
        }
        .txt-pnl{
          opacity: 0;transform: translateX(100%);
          &.trans-right{opacity: 1;transform: translateX(0%);}
        }
         
      }
    }
    @media(max-width:991px){
      &.anime{
        .crypto-text-pnl{
          .img-pnl{
            @include trans;  animation: texty  0.8s linear;
          }
          .txt-pnl{
            @include trans;  animation: text-y  0.8s linear;
          }
        }
      }
    }
  }
  // Swapcrypto

  // Unitdigital
  .Unitdigital{
    .full-main-inner{
      .crypto-text-pnl{
        .img-pnl{
          span{
            &:nth-of-type(1){left: -40px;bottom: 10%;
              @media(max-width:1200px){left:-20px;bottom:5%}
            }
            &:nth-of-type(2){right: -30px;bottom: 10%;
              @media(max-width:1200px){right:-20px;bottom:5%}
            }
            &:nth-of-type(3){right: 15px;top: 30%;
              @media(max-width:1200px){right:-15px;top:25%}
            }
            &:nth-of-type(4){right: 10px;top: 20px;transform: rotate(15deg);
              @media(max-width:1200px){right: 10px;}
            }
            &:nth-of-type(5){left: -70px;top: 30px;transform: rotate(-15deg);
              @media(max-width:1200px){left:-40px;top:25px;}
            }
          }
        }
      }       
    }
    @media(min-width:992px){
      .crypto-text-pnl{
        .img-pnl{opacity: 0;transform: translateX(-100%);
          &.trans-left{opacity: 1;transform: translateX(0%);}
          span{
            &:nth-of-type(1){transform:translate(-200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%, 0%);}
            }
            &:nth-of-type(2){transform:translate(200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%, 0%);}
            }
            &:nth-of-type(3){transform:translate(200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%, 0%);}
            }
            &:nth-of-type(4){transform:translate(-200%, -200%) rotate(15deg);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%, 0%) rotate(15deg);}
            }
            &:nth-of-type(5){transform:translate(-200%, -200%) rotate(-15deg);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%, 0%) rotate(-15deg);}
            }
          }
        }
        .txt-pnl{
          opacity: 0;transform: translateX(100%);
          &.trans-right{opacity: 1;transform: translateX(0%);}
        }
      }
    }
    @media(max-width:991px){
      &.anime{
        .crypto-text-pnl{
          .img-pnl{
            @include trans;  animation: texty  0.8s linear;
          }
          .txt-pnl{
            @include trans;  animation: text-y  0.8s linear;
          }
        }
      }
    }
  }
  // UnitDigital

  // About Panel
  .Aboutpanel{
    .full-main-inner{
      .crypto-text-pnl{
        .img-pnl{
            span{
              border-radius: 15px;border: 2.28px solid rgba(0, 0, 0, 0.05);
              backdrop-filter: blur(19.958343505859375px);box-shadow: -10px 14px 14px 0px rgba(0, 0, 0, 0.06);
              gap: 4px;flex-direction: column;font-size:12px;color:$color;font-weight: 600;
              @media(max-width:1200px){font-size: 10px;}
              img{max-width: 40%;max-height:40%;}
              &:nth-of-type(1){left: -40px;top: 30px;
                @media(max-width:1200px){left: -20px;top:20px;}
              }
              &:nth-of-type(2){right: -30px;top: -25px;
                @media(max-width:1200px){right: -20px;top:-15px;}
              }
              &:nth-of-type(3){right: -15px;bottom: 5px;
                @media(max-width:1200px){right: -15px;bottom:15px;}
              }
              &:nth-of-type(4){left: -40px;bottom: 20%;
                @media(max-width:1200px){left: -25px;bottom:10%;}
              }
            }
          }
        }
    }
    @media(min-width:992px){
      .crypto-text-pnl{
        .img-pnl{opacity: 0;transform: translateX(-100%);
          &.trans-left{opacity: 1;transform: translateX(0%);}
          span{
            &:nth-of-type(1){transform:translate(-200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(2){transform:translate(200%, -200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(3){transform:translate(200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
            &:nth-of-type(4){transform:translate(-200%, 200%);opacity: 0;
              &.appear{opacity: 1;transform: translate(0%);}
            }
          }
        }
        .txt-pnl{
          opacity: 0;transform: translateX(100%);
          &.trans-right{opacity: 1;transform: translateX(0%);}
        }
         
      }
    }
    @media(max-width:991px){
      &.anime{
        .crypto-text-pnl{
          .img-pnl{
            @include trans;  animation: texty  0.8s linear;
          }
          .txt-pnl{
            @include trans;  animation: text-y  0.8s linear;
          }
        }
      }
    }
  }
    // About Panel

  // Hassel Free Crypto
  .HasselFreeCrypto{
    @media(max-width:991px){padding-bottom: 0 !important;}
    .full-main-inner{
      @media(max-width:991px){padding-top: 50px;padding-bottom:50px;}

      
      .bg-layer{
        position: absolute;content: "";
        left: 0;right: 0;bottom: 0;top: 0;z-index: -1;
        background: linear-gradient(180deg, #1B45CE 0%, #0E2368 100%);
      }
      .hasselcrypto-text{display: flex;gap: 50px;max-width: 1500px;align-items: center;
        width: 100%;
        @media(max-width:991px){flex-direction: column;text-align:center;
          .app-store-list{
            justify-content: center;display: flex;gap: 10px;
            @media(max-width:575px){flex-wrap: wrap;}
          }
        }        
        .img-pnl{
          padding: 50px 50px 0;border-radius: 30px;flex-shrink: 0;display: flex;max-width: 500px;
          img{max-width: 50%;}
          @media(max-height:750px){max-width:400px;}
          @media(max-width:1200px){padding: 0;max-width:320px;}
        }
        h2{font-size: 50px;color: $white;
          @media(max-width:1200px){font-size: 35px;}
          span{color: $sky;}
        }
        p{color: $white;font-weight: 400;margin-bottom: 30px;}
      }     
    }

    @media(min-width:992px){
      .bg-layer{
        opacity: 0;transform: translateY(100%);
        &.appear{opacity: 1;transform: translateY(0%);}
      }
      .hasselcrypto-text{
        .img-pnl{
          img{
            &:nth-of-type(1){opacity: 0;transform: translateY(100%);}
            &:nth-of-type(2){opacity: 0;transform: translateY(200%);}
          }
          
          &.trans-right{
            img{
              opacity: 1;transform: translateY(0%);}
            }
          }
        .txt-pnl{
          opacity: 0;transform: translateX(-100%);
          &.trans-left{opacity: 1;transform: translateX(0%);}
        }
         
      }
    }
    @media(max-width:991px){
      &.anime{
        .hasselcrypto-text{
          .img-pnl{
            @include trans;  animation: texty  0.8s linear;
          }
          .txt-pnl{
            @include trans;  animation: text-y  0.8s linear;
          }
        }
      }
    }
  }
  // Hassel Free Crypto

  // Get In Touch
  .GetInTouch{
    @media(max-width:991px){padding-top: 0 !important;padding-bottom: 0 !important;}
    .full-main-inner{flex-direction: column;
      @media(max-width:991px){padding-top: 80px;padding-bottom:50px;}
      @media(max-width:767px){padding-top: 50px;padding-bottom:50px;}
      &:before{
        position: absolute;content: "";right: 0;top: 0;width: 500px;bottom:0;
        background-image: url(../Images/Bg/blue-bg.png);
        background-size: 100% 100%;z-index: -1;
      }
      &:after{
        position: absolute;content: "";left: 0;top: 0;width: 500px;bottom:0;
        background-image: url(../Images/Bg/blue-bg.png);transform: rotate(180deg);
        background-size: 100% 100%;z-index: -1;
        max-width: 100%;
      }
      .get-in-touch-text{
        position: relative;text-align: center;
        span{
          position: absolute;
          display: flex;justify-content: center;align-items: center;
          width: 80px;height: 80px;border-radius: 50%;background-color: $white;
          border: 2.28px solid rgba(0, 0, 0, 0.05);backdrop-filter: blur(19.9583435059px);
          box-shadow: -10px 14px 14px 0px rgba(0, 0, 0, 0.06);
          @media(max-height:900px){width: 60px;height:60px;}
          @media(max-width:1200px){width: 60px;height:60px;}
          @media(max-width:767px){width: 40px;height:40px;}
          img{max-width: 50%;max-height: 50%;}

          &:nth-of-type(1){left: -130px;top: -30px;
            @media(max-height:900px){left: -100px;top: -10px;}
            @media(max-width:1200px){left: -100px;top: -10px;}
            @media(max-width:767px){left: 0px;top: -10px;}
          }
          &:nth-of-type(2){right: -130px;bottom: 30px;
  
            @media(max-height:900px){right: -100px;bottom: 10px;}
            @media(max-width:1200px){right: -100px;bottom: 10px;}
            @media(max-width:767px){right: 0px;bottom: unset;top:-20px;}
          }
        }
      }
      form{width: 100%;max-width: 1000px;margin-top: 30px;
        @media(max-height:900px){margin-top: 0px;}
        @media(max-width:991px){margin-top: 30px;}
      }
    }

    @media(min-width:992px){
      .get-in-touch-text{opacity: 0;transform: translateY(-100%);
        &.trans-down{opacity: 1;transform: translateY(0%);}
        span{
          &:nth-of-type(1){opacity: 0;transform: translate(-100%, -100%);
            &.appear{opacity: 1;transform: translate(0%);}
          }
          &:nth-of-type(2){opacity: 0;transform: translate(100%, 100%);
            &.appear{opacity: 1;transform: translate(0%);}
          }
        }
      }
      form{
        .form-group:nth-child(odd){opacity: 0;transform: translateX(-100%);}
        .form-group:nth-child(even){opacity: 0;transform: translateX(100%);}
        .submit-btn{transform: translateY(100%);opacity: 0;@include trans;}
        &.appear{
          .form-group{opacity: 1;transform: translateX(0);}
          .submit-btn{transform: translateY(0%);opacity: 1;}
        }
      }

    }
    @media(max-width:991px){
      &.anime{
        .get-in-touch-text{@include trans;  animation: texty  0.8s linear;}
        form{@include trans;  animation: text-y  0.8s linear;}
      }
    }
    
  }
  // Get In Touch
}

// Crypto Text Panel
.crypto-text-pnl{
  display: flex;gap: 50px;width: 1500px;align-items: center;max-width: calc(95% - 30px);
  @media(max-width:1200px){gap: 30px;}
  @media(max-width:991px){gap: 50px;
    flex-direction: column-reverse;max-width: calc(100% - 15px);
  }
  .img-pnl{
    background: linear-gradient(0deg, #1B45CE 0%, #0E2368 100%);
    padding: 70px 50px 0;border-radius: 30px;flex-shrink: 0;width: 380px;position: relative;
    @media(max-width:1200px){width: 320px;padding: 50px 30px 0;}
    @media(max-height:750px){width: 260px;}
    @media(max-width:575px){width: 240px;padding: 30px 15px 0;}
    span{position: absolute;width: 70px;height:70px;border-radius: 50%;display: flex;justify-content: center;
      align-items: center;z-index: 2;box-shadow: -10px 14px 14px 0px rgba(0, 0, 0, 0.16);
      @media(max-height:900px){width: 60px;height:60px;}
      @media(max-height:750px){width: 50px;height:50px;}
      @media(max-width:1200px){width: 50px;height:50px;}
        img{max-width: 50%;max-height: 50%;}
        &.text-span{width: auto;height: auto;background-color: $white;font-size: 18px;font-weight: 600;color: $lightblue;text-transform: capitalize;
          padding: 5px 15px;border-radius: 25px;border: none;
        }
        &.trans-span{width: auto;height: auto;background-color: transparent;box-shadow: none;border-radius: 0;
          img{max-height: unset;max-width: unset;}
        }
        &.toggle-span{
          img{max-width: 60px;}
        }
        &.purple-span{background: linear-gradient(0deg, #4F2B8A 0%, #AB78FF 100%);}
        &.etherium-span{background: linear-gradient(0deg, #3E5EBD 0%, #94AEFF 100%);}
        &.btc-span{background: linear-gradient(135deg, #FDE560 14.64%, #FF6F00 85.36%);}
        &.solana-span{background: linear-gradient(0deg, #170E2B 0%, #210066 100%);}
        &.white-span{background-color: $white;}
        &.blue-span{background:linear-gradient(180deg, #2E7EFF 0%, #144BE1 100%);}
    }
  }
  .txt-pnl{padding-left: 40px;
    @media(max-width:991px){text-align: center;padding-left: 0;
      .btn-list{justify-content: center;}
    }
    h3{position: relative;
      img{
        @media(min-width:992px){display: none;}
      }
      &:before{
        position: absolute;content:'';width: 12px;height: 12px;border-radius: 4px;transform: rotate(45deg);
        left: -31px;top: 21px;background:linear-gradient(0deg, #1B45CE 0%, #0E2368 100%);
        @media(max-height:900px){width: 10px;height: 10px;left: -32px;top: 12px;}
        @media(max-width:1200px){width: 10px;height: 10px;left: -32px;top: 12px;}
        @media(max-width:991px){display: none;}
      }
      &:after{
        position: absolute;content:'';width: 30px;height: 30px;border-radius: 8px;left: -40px;top: 12px;
        border: 3px solid;border-color: rgba(27, 69, 206, 1);transform: rotate(45deg);
        @media(max-height:900px){width: 25px;height: 25px;top: 5px;}
        @media(max-width:1200px){width: 25px;height: 25px;top:5px;}
        @media(max-width:991px){display: none;}
      }
    }
    p{font-weight: 400;}
  }
}
// Crypto Text Panel

@media(max-width:991px){
  .Aboutpanel,
  .Unitdigital,
  .Swapcrypto,
  .LargestDex{
    .btn-list{display: none;}
  }
}
.coin-marquee-header{
  display: none;
  svg{display: none;}
  position: relative;
  pointer-events: none;
  div{display: none;}
  &:before{
    position: absolute;content: "";
    left: 15px;right: 30px;bottom: 0px;top: 15px;
    // background-image: url(../Images/Logo/logo.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.coin-marquee-item:hover .coin-marquee-item-inner {
  pointer-events: none;
}
.coin-marquee-container{
  width: 100% !important;
}