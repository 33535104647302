@import "~bootstrap/dist/css/bootstrap.min.css";
@import "Colors";
@import "Fonts";
@import "Mixin";
@import "Custome";
@import "NavBar";
@import "Header";
@import "Button";
@import "Accordian";
@import "Form";
@import "Animation";
@import "Home";
@import "Posts";
@import "Innerpages";
@import "Footer";
