.footer{
    background: $gradientdark;text-align: center;padding-top: 50px;padding-bottom:25px;
    position: relative;left:0;right:0;bottom:0;    
    .footer-logo{max-width: 300px;
        @media(max-width:1400px){max-width: 250px;}
        @media(max-width:991px){max-width: 200px;}
        @media(max-width:575px){max-width: 200px;}
    }
    ul{display: flex;justify-content: center;
        align-items: center;gap: 50px;margin: 40px 0;
        @media(max-width:767px){gap: 25px;}
        @media(max-width:575px){flex-wrap: wrap;gap: 12px;}
        li {
            // @media(max-width:575px){width: 50%;margin-bottom:15px;}
            a{color: $white;font-size: 18px;
                @media(max-width:1200px){font-size: 16px;}
                &:hover{color: $white;
                    @media(max-width:991px){color: $white;}
                }
                @media(max-width:575px){font-size: 12px;text-align:left;}
            }   
        }     
        &.social-media-list{gap: 12px;margin: 0 0 15px;
            li{
                @media(max-width:575px){width: auto;}
                 a{opacity: 0.9;
                &:hover{opacity: 1;}
            }}
        }
        &.Privacy-list{margin: 0;gap: 20px;
            @media(max-width:575px){flex-wrap: unset;gap:10px;}
            li {
                @media(max-width:575px){width: auto;}
                a{font-size: 14px;margin-top: 10px;        
                    @media(max-width:767px){font-size: 14px;}
                    @media(max-width:575px){font-size: 12px;}
                }
       }}
    }
    p{color: $white;margin: 0;font-size: 16px;
        @media(max-width:767px){font-size: 14px;}
        @media(max-width:575px){font-size: 13px;}
    }
}
.social-media-list{gap: 12px;margin: 0 0 15px;display: flex;
    li{
        @media(max-width:575px){width: auto;}
         a{opacity: 0.9;&:hover{opacity: 1;}
    }}
}